import { createReducer, createAction, combineReducers } from '@reduxjs/toolkit';

const selectedItem: string = ""

type stringAction = {
    type: string,
    payload: string
}

export const actionNames = {
    selectItem: "SELECT_ITEM",
}

export const actions = {
    SelectItem: createAction<string>(actionNames.selectItem),
}

const ItemSelector = (state = selectedItem, action: stringAction) => {
    const newState = (action.type === actionNames.selectItem) ? 
    action.payload : state
    return newState
}


export const RootReducer = combineReducers ({
    selectedItem: ItemSelector
})

export type RootState = ReturnType<typeof RootReducer>
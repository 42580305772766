import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const HomePage = () => {

  return (
    <div className="app mainPage">
         <h1>
            Home page
         </h1>
    </div>
    )
}

export default HomePage
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const SignupPage = () => {

  return (
    <div className="app mainPage">
         <h1>
            Signup page
         </h1>
    </div>
    )
}

export default SignupPage
import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./scss/app.scss";
import SignupPage from "./pages/signup";
import HomePage from "./pages/home";



const App = () => {

  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />}/>
          <Route path="/signup/:key" element={<SignupPage />}/>
        </Routes>
    </BrowserRouter>
  );
};

export default App;